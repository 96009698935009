import React from 'react';
import { useNavigate } from 'react-router-dom';
import './About.css';

const About = () => {
    const navigate = useNavigate();

    const handleLearnMoreClick = () => {
        navigate('/');
        const section = document.getElementById("aylix-dashboard");
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
    };

    const handleGetStartedClick = () => {
        navigate('/');
        setTimeout(() => {
            const section = document.getElementById("experience-section");
            if (section) {
                section.scrollIntoView({ behavior: "smooth" });
            }
        }, 100); // Timeout to ensure the page has navigated before scrolling
    };
    return (
        <div className="about-page">
            <section className="main-image">
                <div className="video-container-abt">
                    <img className="video-abt" src="images/About2.gif" alt="About GIF" />
                </div>
                <div className="about-overlay-text">
                    <h1><span className="highlight">AI Intelligence, Tailored for <br />Every Team's Success</span></h1>
                    <h2>
                        <span className="highlight2">Ask Dynamic Questions,</span> Uncover Real-Time Insights for Every Domain
                    </h2>
                    <div className="video-container-abt-gif">
                        <img className="video-abt-1" src="images/About2.gif" alt="About GIF" />
                    </div>
                    <p>
                        Empower your teams with secure, customized dashboards—Marketing refines strategies with real-time campaign analytics. Sales tracks revenue trends and discovers new growth opportunities. HR optimizes workforce performance and enhances employee engagement. With secure, customized dashboards, every team gets the data they need—exactly when they need it.
                    </p>
                    <div className="button-container">
    <button className="btn get-started" onClick={handleGetStartedClick} >Get Started</button>
    <button className="btn learn-more" onClick={handleLearnMoreClick} >Learn More</button>
  </div>
                </div>  
            </section>

            <section className="about-how-it-works">
                <div className="how-it-works-wave"></div>
                <h2><span className="highlight">How Aylix Works:</span></h2>
                <div className="how-it-works-container">
                    <div className="how-it-works-item">
                        <div className="how-it-works-marker">1</div>
                        <h3>Secure Data Ingestion</h3>
                        <p>Admins can securely upload and manage various data formats like CSV, Excel, and SQL. Aylix ensures end-to-end encryption and applies strict access controls to protect sensitive information.</p>
                    </div>
                    <div className="how-it-works-item">
                        <div className="how-it-works-marker">2</div>
                        <h3>Intuitive Data Exploration</h3>
                        <p>Aylix offers an easy-to-use interface with interactive dashboards and customizable views, allowing users to explore data and identify trends for informed decision-making.</p>
                    </div>
                    <div className="how-it-works-item">
                        <div className="how-it-works-marker">3</div>
                        <h3>Natural Language Queries</h3>
                        <p>Users can ask questions in plain language, and Aylix’s AI provides real-time, accurate responses, eliminating the need for complex SQL queries.</p>
                    </div>
                    <div className="how-it-works-item">
                        <div className="how-it-works-marker">4</div>
                        <h3>AI-Generated Insights</h3>
                        <p>Aylix automatically generates visualizations and reports, uncovering hidden patterns and trends, ensuring users have access to the latest data insights for proactive decisions.</p>
                    </div>
                    <div className="how-it-works-item">
                        <div className="how-it-works-marker">5</div>
                        <h3>Role-Based Access Control</h3>
                        <p>Administrators can define roles and permissions, ensuring users only see the relevant data for their role while maintaining security and streamlining workflows.</p>
                    </div>
                </div>
            </section>

            <section className="about-empower-teams">
                <h2><span className="highlight">How Aylix Empowers Your Teams</span></h2>
                <div className="flowchart-container">
                    <div className="flowchart-item">
                        <div className="flowchart-inner">
                            <div className="flowchart-front">
                                <h3>Marketing</h3>
                                <img src="/images/marketing.gif" alt="Marketing GIF" />
                            </div>
                            <div className="flowchart-back">
                                <h3>Marketing</h3>
                                <p>Leverage Aylix to unlock campaign performance, sales insights, and lead generation. Drive ROI by analyzing customer behavior and refining strategies based on real-time data. Predict future trends and adapt campaigns seamlessly to stay ahead. Access tailored insights that transform data into actionable marketing results.</p>
                            </div>
                        </div>
                    </div>
                    <div className="flowchart-item">
                        <div className="flowchart-inner">
                            <div className="flowchart-front">
                                <h3>Sales</h3>
                                <img src="/images/sales.gif" alt="Sales GIF" />
                            </div>
                            <div className="flowchart-back">
                                <h3>Sales</h3>
                                <p>Aylix identifies high-potential leads and forecasts sales with precision. Equip your sales team with insights into customer needs, boosting deal closure rates. Monitor performance effortlessly and tailor approaches using automated dashboards. Drive consistent growth through data-driven strategies.</p>
                            </div>
                        </div>
                    </div>
                    <div className="flowchart-item">
                        <div className="flowchart-inner">
                            <div className="flowchart-front">
                                <h3>Finance</h3>
                                <img src="/images/finance.gif" alt="Finance GIF" />
                            </div>
                            <div className="flowchart-back">
                                <h3>Finance</h3>
                                <p>Simplify financial performance analysis and forecasting with Aylix. Detect cost-saving opportunities and monitor KPIs to ensure profitability. Automate reporting and focus on strategic planning with real-time insights. Make confident decisions by predicting future trends accurately.</p>
                            </div>
                        </div>
                    </div>
                    <div className="flowchart-item">
                        <div className="flowchart-inner">
                            <div className="flowchart-front">
                                <h3>Operations</h3>
                                <img src="/images/operations.gif" alt="Operations GIF" />
                            </div>
                            <div className="flowchart-back">
                                <h3>Operations</h3>
                                <p>Enhance operational efficiency by identifying bottlenecks and optimizing workflows. Aylix streamlines processes, predicts resource needs, and reduces costs. Improve productivity with automated insights into supply chain and internal operations. Keep operations agile and adaptive with continuous performance tracking.</p>
                            </div>
                        </div>
                    </div>
                    <div className="flowchart-item">
                        <div className="flowchart-inner">
                            <div className="flowchart-front">
                                <h3>Human Resource</h3>
                                <img src="/images/hr.gif" alt="HR GIF" />
                            </div>
                            <div className="flowchart-back">
                                <h3>Human Resources</h3>
                                <p>Transform HR with insights into recruitment, performance, and retention. Aylix enables smarter workforce planning by identifying trends and predicting challenges. Boost engagement and reduce turnover by analyzing employee data. Align HR strategies with business goals to foster a thriving workplace.</p>
                            </div>
                        </div>
                    </div>
                    <div className="flowchart-item">
                        <div className="flowchart-inner">
                            <div className="flowchart-front">
                                <h3>Research and Development</h3>
                                <img src="/images/rd.gif" alt="Research and Development GIF" />
                            </div>
                            <div className="flowchart-back">
                                <h3>Research and Development</h3>
                                <p>Accelerate innovation with data-driven insights from Aylix. Analyze market trends, track project progress, and identify emerging opportunities. Enhance product development by leveraging predictive analytics and real-time feedback. Drive competitive advantage by aligning R&D initiatives with evolving customer needs and market demands.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <footer className="about-footer">
                <div className="logo-p">
                    <img src="/images/Aylix Logo (30).png" alt="Logo" />
                </div>
                <p>Copyright © 2024 AYLIX LLC. All rights reserved. Empowering organizations with AI-driven insights.</p>
                <div className="footer-links">
                    <a href="/privacy-policy">Privacy Policy</a>
                </div>
            </footer>
        </div>
    );
};

export default About;
