import React from "react";
import { useNavigate } from "react-router-dom";
import "./ExperienceAylix.css";
import { FcComboChart } from "react-icons/fc";
import { ImBullhorn } from "react-icons/im";
import { HiOutlineLightBulb } from "react-icons/hi";

const ExperienceAylix = () => {
  const navigate = useNavigate();

  const handleExploreClick = (cardType) => {
    navigate("/login", { state: { cardType } }); 
  };

  return (
    <div id="experience-section" className="experience-section">
      <h1>Unlock the Power of Data</h1>
      <p>
        Dive into the future of data-driven decision-making with Aylix. 
        Our cutting-edge AI solutions offer unparalleled insights into your organization's critical areas.<br />
        Explore our specialized dashboards and unlock the potential of your data.
      </p>

      <div className="cards-container">
        <div className="card">
          <div className="first-content">
            <h2>30%</h2> 
            <h3>Boost in Operational Efficiency</h3>
            <p>Streamline procurement by gaining insights into supplier performance.</p>
          </div>
          <div className="second-content">
            <FcComboChart className="card-icon" />
            <h3>PROCUREMENT INSIGHTS</h3>
            <p>Optimize Your Spend</p>
            <button className="open-ai-btn" onClick={() => handleExploreClick('Procurement')}>
              Explore here
            </button>
          </div>
        </div>

        <div className="card">
          <div className="first-content">
            <h2>3x</h2> 
            <h3>More Revenue Opportunities Identified</h3>
            <p>Discover hidden sales trends and focus on high-impact leads.</p>
          </div>
          <div className="second-content">
            <ImBullhorn className="card-icon" />
            <h3>SALES DATA INSIGHTS</h3>
            <p>Boost Sales Revenue</p>
            <button className="open-ai-btn" onClick={() => handleExploreClick('Sales')}>
              Explore here
            </button>
          </div>
        </div>

        <div className="card">
          <div className="first-content">
            <h2>40%</h2> 
            <h3>Increase in Customer Issue Resolution</h3>
            <p>Proactively resolve customer concerns with predictive analytics.</p>
          </div>
          <div className="second-content">
            <HiOutlineLightBulb className="card-icon" />
            <h3>CUSTOMER SUCCESS INSIGHTS</h3>
            <p>Elevate Customer Care</p>
            <button className="open-ai-btn" onClick={() => handleExploreClick('Customer')}>
              Explore here
            </button>
          </div>
        </div>
      </div>

      <div className="logo">
        <img 
          src="/images/Aylix Logo (30).png" 
          alt="Aylix Logo" 
          className="aylix-logo"
        />
      </div>
      
      <footer className="aylix-footer">
        <p>Copyright © 2024 AYLIX LLC. All rights reserved. Empowering organizations with AI-driven insights.</p>
        <div className="footer-links">
          <a href="/privacy-policy">Privacy Policy</a>
        </div>
      </footer>
    </div>
  );
};

export default ExperienceAylix;
