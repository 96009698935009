import React, { useState, useEffect } from 'react';
import './Chatbot.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faHurricane } from '@fortawesome/free-solid-svg-icons';
import { awsInstance, geminiInstance, openaiInstance } from '../../axiosInstance';
import { useLocation } from 'react-router-dom';

const Chatbot = () => {
  const [query, setQuery] = useState('');
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [chatHistory, setChatHistory] = useState([]); 
  const location = useLocation();
  const { cardType, username } = location.state;

  useEffect(() => {
    console.log(`User ${username} has logged in and is using ${cardType}`);
  }, [username, cardType]);

  const formatResponse = (text) => {
    if (!text) return '';

    return text
      .split('\n')
      .map((line, index) => {
        if (line.startsWith('*') || line.startsWith('-')) {
          return `<li key=${index}>${line.replace(/^[*-]\s*/, '')}</li>`;
        }
        if (line.startsWith('# ')) {
          return `<h2 key=${index}>${line.replace('# ', '')}</h2>`;
        }
        if (line.startsWith('## ')) {
          return `<h3 key=${index}>${line.replace('## ', '')}</h3>`;
        }
        return `<p key=${index}>${line}</p>`;
      })
      .join('');
  };

  const handleSearch = async () => {
    if (!query) return;

    setLoading(true);
    try {
      let apiResponse;
      if (cardType === 'Procurement') {
        apiResponse = await awsInstance.post('/ask-bot', { query });
      } else if (cardType === 'Sales') {
        apiResponse = await geminiInstance.post('/ask-bot', { query });
      } else if (cardType === 'Customer') {
        apiResponse = await openaiInstance.post('/ask-bot', { query });
      } else {
        throw new Error('Unknown card type');
      }

      const botResponse = apiResponse.data.response;
      const formattedResponse = formatResponse(botResponse);

      setChatHistory(prevHistory => [
        ...prevHistory,
        { question: query, response: formattedResponse }
      ]);

      setResponse(formattedResponse);
      setQuery('');
    } catch (error) {
      console.error('Error during search:', error);
      setResponse('Failed to get a response. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <main>
      <div className="chat-container">
        <div className="chat-history">
          {chatHistory.map((chat, index) => (
            <div key={index} className="chat-message-container">
              <div className="chat-message user-message">
                <div className="message-bubble">
                  <p>{chat.question}</p>
                </div>
              </div>

              <div className="chat-message bot-message">
                <div className="message-bubble">
                  <div dangerouslySetInnerHTML={{ __html: chat.response }} />
                </div>
              </div>
            </div>
          ))}
        </div>

        {loading && (
          <div className="loading-icon">
            <FontAwesomeIcon icon={faHurricane} spin size="2x" />
          </div>
        )}

        <div className="search-bar">       
          <input
            type="text"
            placeholder="Ask me anything about your data!"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            disabled={loading}
          />
          <button className="send-icon" onClick={handleSearch} disabled={loading}>
            <FontAwesomeIcon icon={faPaperPlane} />
          </button>
        </div>
      </div>
    </main>
  );
};

export default Chatbot;
